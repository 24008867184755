<template>
  <div class="main-container">
    <div class="create-account-container">
      <div class="create-account-header-text">Edit Account Details</div>
      <a-form>
        <div class="create-account-basic-details">
          <div class="basic-account-info-form">
            <div class="form-sub-section">
              <div class="add-account-form-elements left-form-elements">
                <a-form-item>
                  <span class="input-label">Company</span
                  ><span class="required-input">*</span>
                  <a-input class="form-input"></a-input>
                </a-form-item>
              </div>
              <div class="add-account-form-elements">
                <a-form-item>
                  <span class="input-label">Company ID</span
                  ><span class="required-input">*</span>
                  <a-input disabled class="form-input"></a-input>
                  <a-checkbox v-model:checked="companyStatus"
                    >Set as active</a-checkbox
                  >
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div class="add-account-form-elements left-form-elements">
                <a-form-item>
                  <span class="input-label">Company Type</span
                  ><span class="required-input">*</span>
                  <a-select
                    class="select-input"
                    v-model:value="companyType"
                    :options="companyTypeOptions"
                  ></a-select>
                </a-form-item>
              </div>
              <div class="add-account-form-elements">
                <a-form-item>
                  <span class="input-label">Country</span
                  ><span class="required-input">*</span>
                  <a-select
                    class="select-input"
                    v-model:value="country"
                    :options="countryOptions"
                  ></a-select>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div class="add-account-form-elements left-form-elements">
                <a-form-item>
                  <span class="input-label">Date</span>
                  <span class="required-input">*</span>
                  <a-date-picker
                    class="date-input"
                    style="border-radius: 15px !important; margin-top: 0.75rem"
                  ></a-date-picker>
                </a-form-item>
              </div>
              <div class="add-account-form-elements">
                <a-form-item>
                  <span class="input-label">Website</span>
                  <span class="required-input">*</span>
                  <a-input class="form-input"></a-input>
                </a-form-item>
              </div>
            </div>
            <a-divider class="section-divider" />
            <div class="form-sub-section">
              <div class="add-account-form-elements">
                <a-form-item>
                  <span class="input-label">Account Manager</span>
                  <span class="required-input">*</span>
                  <a-select
                    class="select-input"
                    mode="tags"
                    showArrow
                    label-in-value
                    v-model:value="account_manager"
                    :filter-option="false"
                    :options="accountManagerOptions"
                    @search="fetchUserData"
                    style="margin-top: 0.75rem"
                  ></a-select>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div class="add-account-form-elements">
                <a-form-item>
                  <span class="input-label">Sector</span>
                  <span class="required-input">*</span>
                  <a-select
                    class="select-input"
                    mode="tags"
                    showArrow
                    label-in-value
                    v-model:value="sector"
                    :filter-option="false"
                    :options="sectorOptions"
                    @search="fetchSectorData"
                    style="margin-top: 0.75rem"
                  ></a-select>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div class="add-account-form-elements">
                <a-form-item>
                  <span class="input-label">Industry</span>
                  <span class="required-input">*</span>
                  <a-select
                    class="select-input"
                    mode="tags"
                    showArrow
                    label-in-value
                    v-model:value="industry"
                    :filter-option="false"
                    :options="industryOptions"
                    @search="fetchIndustryData"
                    style="margin-top: 0.75rem"
                  ></a-select>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div class="add-account-form-elements">
                <a-form-item>
                  <span class="input-label">Profile</span>
                  <span class="required-input">*</span>
                  <a-textarea
                    class="form-text-area"
                    v-model:value="profileTextArea"
                    :autoSize="{ minRows: 4, maxRows: 6 }"
                  />
                </a-form-item>
              </div>
            </div>
          </div>
        </div>
        <div class="form-action-btns">
          <a-button type="link" class="clear-button">Exit</a-button>
          <a-button class="create-account-btn"> Update Account </a-button>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from "vue";
import { Form } from "ant-design-vue";
import Services from "../../../services/apis";

const addAccountForm = Form.useForm;

let entityRef = reactive({
  entityValue: "",
  entityList: ["Entity1"],
});

let officeRef = reactive({
  officeName: "",
  officeTypeValue: "",
  officeAddress: "",
  officeList: [],
});

let rmStatusObject = reactive({
  cicValue: "",
  rmStatusValue: "",
  priorityValue: "",
  rmStatusList: [],
});

let subTeamRagObject = reactive({
  subTeamValue: "",
  ragStatusValue: "",
  subTeamRagList: [],
});

const addEntities = () => {
  let entityValue = entityRef.entityValue;
  let entityList = entityRef.entityList;

  if (entityValue && entityList.indexOf(entityValue) === -1) {
    entityList = [...entityList, entityValue];
  }

  Object.assign(entityRef, {
    entityValue: "",
    entityList,
  });
};

// const addOfficeDetails = () => {
//   let;
// };

const resetEntities = () => {
  entityRef.entityValue = "";
};

const resetOfficeFields = () => {
  officeRef.officeName = "";
  officeRef.officeTypeValue = "";
  officeRef.officeAddress = "";
};

const resetSubTeamRagFields = () => {
  subTeamRagObject.subteamValue = "";
  subTeamRagObject.ragStatusValue = "";
};

const clientPersonRMStatusColumns = [
  {
    key: "cic",
    dataIndex: "cic",
    title: "CIC",
    colSpan: 1,
    textalign: "left",
  },
  {
    key: "rmstatus",
    dataIndex: "rmstatus",
    title: "RM Status",
    colSpan: 1,
    textalign: "center",
  },
  {
    key: "priority",
    dataIndex: "priority",
    title: "Priority",
  },
];

const termsColumns = [
  {
    key: "level",
    dataIndex: "level",
    title: "Level",
    textalign: "left",
    colspan: 3,
  },
  {
    key: "isFixed",
    dataIndex: "isFixed",
    title: "Is Fixed",
    textalign: "center",
  },
  {
    key: "fixed_fee",
    dataIndex: "fixed_fee",
    title: "Fixed Fee",
  },
  {
    key: "percentage",
    dataIndex: "percentage",
    title: "Percentage%",
  },
  {
    key: "fee_limit",
    dataIndex: "fee_limit",
    title: "Fee Limit",
  },
];

const clientPersonRMStatusRowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
};

const clientPersonColumns = [
  {
    key: "unit",
    dataIndex: "unit",
    title: "Unit",
    textalign: "left",
  },
  {
    key: "name",
    dataIndex: "name",
    title: "Name",
    colSpan: 1,
    textalign: "center",
  },
  {
    key: "designation",
    dataIndex: "designation",
    title: "Designation",
  },
  {
    key: "mobile",
    dataIndex: "mobile",
    title: "Mobile No.",
  },
  {
    key: "phone",
    dataIndex: "phone",
    title: "Phone Office",
  },
  {
    key: "email",
    dataIndex: "email",
    title: "Email ID",
  },
  {
    key: "dob",
    dataIndex: "dob",
    title: "DOB",
  },
];

const meetingColumns = [
  {
    key: "clientPerson",
    dataIndex: "clientPerson",
    title: "Client Person",
    textalign: "left",
  },
  {
    key: "date",
    dataIndex: "date",
    title: "Date",
    textalign: "center",
  },
  {
    key: "department",
    dataIndex: "department",
    title: "Department",
  },
  {
    key: "lead_cic",
    dataIndex: "lead_cic",
    title: "Lead CIC",
  },
  {
    key: "attended_cic",
    dataIndex: "attended_cic",
    title: "Attended CIC",
  },
  {
    key: "tracker_type",
    dataIndex: "tracker_type",
    title: "Tracker Type",
  },
  {
    key: "team",
    dataIndex: "team",
    title: "Team",
  },
  {
    key: "sub_team",
    dataIndex: "sub_team",
    title: "Sub Team",
  },
];

const clientPersonRowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
};

export default defineComponent({
  setup() {
    let binaryOptions = ref([
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" },
    ]);
    let priorityOptions = ref([
      { value: "low", label: "Low" },
      { value: "moderate", label: "Moderate" },
      { value: "high", label: "High" },
    ]);

    let profileTextArea = ref("");
    let clientPersonTextArea = ref("");
    let meetingTextArea = ref("");
    let cicOptions = ref([]);
    let rmStatusOptions = ref([]);
    let companyTypeOptions = ref([]);
    let countryOptions = ref([]);
    let accountManagerOptions = ref([]);
    let sectorOptions = ref([]);
    let industryOptions = ref([]);
    let officeTypeOptions = ref([]);
    let subTeamOptions = ref([]);
    let ragStatusOptions = ref([]);
    let documentUploadRadiobtns = ref(1);

    const fetchSectorData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE" };
      Services.getSectorDetail(payload)
        .then((response) => {
          const { data } = response;
          sectorOptions.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchIndustryData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE" };
      Services.getIndustryDetail(payload)
        .then((response) => {
          const { data } = response;
          industryOptions.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchUserData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE", type: "MEMBER", pageNo: 0 };
      Services.userListing(payload)
        .then((response) => {
          const { data } = response;
          accountManagerOptions.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    // OnMounted

    onMounted(() => {
      fetchSectorData();
      fetchIndustryData();
      fetchUserData();

      //
      let user_listing_payload = {
        q: "",
        pageNo: 0,
        mode: "AUTOCOMPLETE",
        type: "MEMBER",
      };

      Services.userListing(user_listing_payload)
        .then((response) => {
          const { data } = response;
          cicOptions.value = data.data.map(
            ({ id: value, firstName: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });

      // RM Status Listing
      Services.getRmStatusDetail({ types: ["RM"] })
        .then((response) => {
          const { data } = response;
          rmStatusOptions.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });

      // RAG STATUS Listing
      Services.getRmStatusDetail({ types: ["RAG"] })
        .then((response) => {
          const { data } = response;
          ragStatusOptions.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });

      // Sub Team Listing
      Services.teamListing({ types: ["SUB_TEAM"] })
        .then((response) => {
          const { data } = response;
          subTeamOptions.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });

      // Company Type Listing
      Services.genderListing({ types: ["COMPANY_TYPES"] })
        .then((response) => {
          const { data } = response;
          companyTypeOptions.value = data[0].data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });

      Services.countryListing({ types: ["COMPANY_TYPES"] })
        .then((response) => {
          const { data } = response;
          countryOptions.value = data[0].data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
      // Office Type and Unit type Listing
      // Services.genderListing({ types: ["UNIT_TYPES"] })
      //   .then((response) => {
      //     const { data } = response;
      //     officeTypeOptions.value = data[0].data.map(
      //       ({ id: value, name: label, ...rest }) => ({
      //         value,
      //         label,
      //         ...rest,
      //       })
      //     );
      //   })
      //   .catch((e) => {
      //     console.log("error :>> ", e);
      //   });
    });

    const addRmStatus = () => {
      let cicValue = "Jayesh";
      let rmStatusValue = rmStatusOptions.value.filter(
        (rm) => rm.value === rmStatusObject.rmStatusValue
      );
      let priorityValue = rmStatusObject.priorityValue;
      let rmStatusList = rmStatusObject.rmStatusList;

      let id = rmStatusList.length + 1;
      let rmStatus = {
        key: id,
        cic: cicValue,
        rmstatus: rmStatusValue[0].label,
        rmStatusId: rmStatusValue[0].value,
        priority: priorityValue,
      };
      rmStatusList.push(rmStatus);

      Object.assign(rmStatusObject, {
        cicValue: "",
        rmStatusValue: "",
        priorityValue: "",
        rmStatusList,
      });
    };

    const addOfficeInfo = () => {
      let officeName = officeRef.officeName;
      let officeAddress = officeRef.officeAddress;
      let officeList = officeRef.officeList;
      let officeTypeValue = officeTypeOptions.value.filter(
        (type) => type.value === officeRef.officeTypeValue
      );
      let id = officeList.length + 1;
      let officeObject = {
        key: id,
        officeName: officeName ? officeName : "-",
        officeType: officeTypeValue.length > 0 ? officeTypeValue[0].label : "-",
        officeTypeId:
          officeTypeValue.length > 0 ? officeTypeValue[0].value : "",
        officeAddress: officeAddress ? officeAddress : "-",
      };
      officeList.push(officeObject);
      Object.assign(officeRef, {
        officeName: "",
        officeTypeValue: "",
        officeAddress: "",
        officeList,
      });
    };

    const addSubTeamRGInfo = () => {
      let subTeamValue = subTeamOptions.value.filter(
        (subTeam) => subTeam.value === subTeamRagObject.subTeamValue
      );
      let ragStatusValue = ragStatusOptions.value.filter(
        (rag) => rag.value === subTeamRagObject.ragStatusValue
      );
      let subTeamRagList = subTeamRagObject.subTeamRagList;
      let id = subTeamRagList.length + 1;
      let ragObject = {
        key: id,
        subTeam: subTeamValue.length > 0 ? subTeamValue[0].label : "-",
        subTeamId: subTeamValue.length > 0 ? subTeamValue[0].value : "",
        ragStatus: ragStatusValue.length > 0 ? ragStatusValue[0].label : "-",
        ragStatusId: ragStatusValue.length > 0 ? ragStatusValue[0].value : "",
      };
      subTeamRagList.push(ragObject);
      Object.assign(subTeamRagObject, {
        subTeamValue: "",
        ragStatusValue: "",
        subTeamRagList,
      });
    };

    const handleChange = (info) => {
      const status = info.file.status;

      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }

      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
        let filesLists = info.fileList;
        for (let i = 0; i < filesLists; i++) {
          modelRef.documents.push(filesLists[i].name);
        }
        console.log(info.fileList);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    };

    return {
      documentUploadRadiobtns,
      // Table Row Selection Variables
      clientPersonRowSelection,
      clientPersonRMStatusRowSelection,
      // Table Column Definitions
      clientPersonColumns,
      meetingColumns,
      clientPersonRMStatusColumns,
      termsColumns,
      // Select Option Variables
      cicOptions,
      rmStatusOptions,
      ragStatusOptions,
      subTeamOptions,
      binaryOptions,
      priorityOptions,
      companyTypeOptions,
      countryOptions,
      officeTypeOptions,
      accountManagerOptions,
      sectorOptions,
      industryOptions,

      // Select Input variables
      birthdayValue: ref(""),
      termsSelectValue: ref(""),
      newsletterValue: ref(""),
      companyType: ref(""),
      country: ref(""),
      account_manager: ref([]),
      sector: ref([]),
      industry: ref([]),

      // Funtions
      fetchSectorData,
      fetchIndustryData,
      fetchUserData,
      addEntities,
      resetEntities,
      resetOfficeFields,
      resetSubTeamRagFields,
      addRmStatus,
      addOfficeInfo,
      addSubTeamRGInfo,
      handleChange,

      // Table data objects
      entityRef,
      officeRef,
      rmStatusObject,
      subTeamRagObject,
      // Text Area Variabbles
      profileTextArea,
      clientPersonTextArea,
      meetingTextArea,

      // Set as Active CheckBox value
      companyStatus: ref(true),
      clientPersonStatus: ref(true),
      termsStatus: ref(true),
    };
  },
});
</script>

<style lang="scss">
@import "../styles/CreateCompany.scss";
</style>
